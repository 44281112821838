import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import CustomLoader from "./components/loader/loader";

//************ Routes Layout *************//
import RootLayout from "./layouts/RootLayout";
//************ Used Routes *************//

import AboutPage from "./pages/about/AboutPage";
import Contact from "./pages/contact/";
import Home from "./pages/home/Home";

import MyDashboard from "./pages/dashboard/dashboard";
import Subscriptions from "./pages/subscriptions/subscriptions";

import AddReview from "./pages/reviews/addReview";
import ReviewDetails from "./pages/reviews/details";
import ReviewEdit from "./pages/reviews/edit";
import Reviews from "./pages/reviews/getAll";

import AddBlog from "./pages/blog/add";
import BlogDetails from "./pages/blog/details";
import Blogs from "./pages/blog/getAll";

import DemoAdd from "./pages/demos/add";
import DemoDetails from "./pages/demos/details";
import DemoEdit from "./pages/demos/edit";
import Demos from "./pages/demos/getAll";
import PastDemos from "./pages/demos/pastDemos";

import AddCourse from "./pages/courses/add";
import CloneCourse from "./pages/courses/clone";
import CourseDetails from "./pages/courses/details";
import EditCourse from "./pages/courses/edit";
import CourseListing from "./pages/courses/getAll";

import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import CloneDemo from "./pages/demos/clone";
import Enquiry from "./pages/enquiry/enquiry";

import MyCourses from "./pages/dashboard/myCourses/myCourses";
import MyDemos from "./pages/dashboard/myDemos/myDemos";
import MyEvents from "./pages/dashboard/myEvents/myEvents";
import MyGroups from "./pages/dashboard/myGroups/myGroups";

import DraftForm from "./components/campaignEmail/draftForm";
import CampaignDrafts from "./components/campaignEmail/drafts";
import EmailCampaign from "./components/campaignEmail/emailCampaign";
import CampaignDashboard from "./pages/campaignDashboard/campaignDashboard";

import TemplateForm from "./components/campaignEmail/templateForm";
import Templates from "./components/campaignEmail/templates";

import EmailListAdd from "./components/campaignEmailLists/EmailListAdd";
import EmailListContactForm from "./components/campaignEmailLists/EmailListContactForm";
import EmailContacts from "./components/campaignEmailLists/emailContacts";
import EmailLists from "./components/campaignEmailLists/emailLists";

import ShareData from "./pages/share/share";
import RequestSponsorship from "./pages/sponsorship/requestSponsorship";

import ContentGenerator from "./pages/admin/contentGenerator/contentGenerator";
import ChatAI from "./pages/chatAI/chatAI";

import Coordinator from "./pages/coordinator/coordinator";
import Corporates from "./pages/corporates/corporates";
import HowItWorks from "./pages/howItWorks/howItWorks";
import ProfileEdit from "./pages/profile/edit";
import Profile from "./pages/profile/profile";

import AllContacts from "./pages/myContacts/allContacts";
import MyContacts from "./pages/myContacts/myContacts";

import WhatsappContacts from "./components/campaignWhatsappLists/whatsappContacts";
import WhatsappListsAddForm from "./components/campaignWhatsappLists/whatsappListAdd";
import WhatsappListContactForm from "./components/campaignWhatsappLists/whatsappListContactForm";
import WhatsappLists from "./components/campaignWhatsappLists/whatsappLists";

import TelegramContacts from "./components/campaignTelegramLists/telegramContacts";
import TelegramListsAddForm from "./components/campaignTelegramLists/telegramListAdd";
import TelegramListContactForm from "./components/campaignTelegramLists/telegramListContactForm";
import TelegramLists from "./components/campaignTelegramLists/telegramLists";

import PhoneContacts from "./components/campaignPhoneLists/phoneContacts";
import PhoneListsAddForm from "./components/campaignPhoneLists/phoneListAdd";
import PhoneListContactForm from "./components/campaignPhoneLists/phoneListContactForm";
import PhoneLists from "./components/campaignPhoneLists/phoneLists";

import GroupAdd from "./pages/dashboard/myGroups/groupAdd";
import GroupEdit from "./pages/dashboard/myGroups/groupEdit";
import GroupItems from "./pages/dashboard/myGroups/groupItems";
import UniqueItems from "./pages/dashboard/myGroups/uniqueItems";

import SMSCampaign from "./components/campaignPhone/campaignPhone";
import TelegramCampaign from "./components/campaignTelegram/campaignTelegram";
import WhatsAppCampaign from "./components/campaignWhatsapp/whatsappCampaign";

import DailyNewsletter from "./components/dailyNewsletter/dailyNewsletter";
import Analytics from "./pages/campaignDashboard/analytics/analytics";
import GeneratedEmail from "./pages/emailTemplate/generatedEmail";

import SubDomainRouter from "./components/subDomainRouter/subDomainRouter";
import TokenDetails from "./components/useUserDetails/useTokenDetails";
import useUserData from "./components/useUserDetails/useUserDetails";

import UploadFiles from "./pages/admin/uploader/fileupload";
import JoinAsTrainer from "./pages/joinAsTrainer/joinAsTrainer";
import Pay from "./pages/pay/paymentNew";

import LoginSuccess from "./components/login/loginsuccess";
import ChangePassword from "./pages/auth/changepassword/changepassword";

import TrainerAdd from "./pages/trainers/add";
import TrainerDetails from "./pages/trainers/details";
import TrainerEdit from "./pages/trainers/edit";
import Trainers from "./pages/trainers/getAll";

import AdminPage from "./pages/admin/admin";
import ConfigsPage from "./pages/admin/configs/config";
import AllEventsRegistrants from "./pages/admin/registrants/registrants";
import Users from "./pages/admin/users/users";
import UsersEdit from "./pages/admin/users/usersEdit";
import Registrants from "./pages/registrants/Registrants";

import SuperAdminDashboard from "./pages/superAdmin/SuperAdminDashboard";
import AddConfigs from "./pages/superAdmin/config/AddConfig";
import CloneConfig from "./pages/superAdmin/config/CloneConfig";
import EditConfig from "./pages/superAdmin/config/EditConfig";
import ConfigManagement from "./pages/superAdmin/config/configManagement";
import SuperAdminUsers from "./pages/superAdmin/users/users";
import SuperAdminUsersAdd from "./pages/superAdmin/users/usersAdd";
import SuperAdminUsersEdit from "./pages/superAdmin/users/usersEdit";

import CoordinatorDashboard from "./pages/coordinatorDashboard/coordinatorDashboard";
import MyTrainers from "./pages/coordinatorDashboard/myTrainers/myTrainers";
import NewsletterCampaign from "./pages/newsletterCampaign/newsletterCampaign";

import CoordinatorGuide from "./pages/howItWorks/coordinatorGuide";
import InstructorGuide from "./pages/howItWorks/instructorGuide";

import AcceleratorRegister from "./pages/accelerator/register";
import Register from "./pages/register/register";

import Meetups from "./pages/meetups/meetups";

import AddEditStartup from "./pages/startups/add";
import StartupDetails from "./pages/startups/details";
import Startups from "./pages/startups/startup";

import AddEditServiceProvider from "./pages/serviceProviders/add";
import ServiceProviderDetails from "./pages/serviceProviders/details";
import ServiceProvider from "./pages/serviceProviders/listing";

import AddEditSpeakers from "./pages/speakers/add";
import SpeakerDetails from "./pages/speakers/details";
import Speakers from "./pages/speakers/speakers";

import AddEditMentors from "./pages/mentors/add";
import MentorDetails from "./pages/mentors/details";
import Mentors from "./pages/mentors/listing";

import AddEditFreelancers from "./pages/freelancers/add";
import FreelancerDetails from "./pages/freelancers/details";
import Freelancers from "./pages/freelancers/listing";

import AddEditIntern from "./pages/interns/add";
import InternDetails from "./pages/interns/details";
import Interns from "./pages/interns/listing";

import AddEditFounder from "./pages/founders/add";
import FounderDetails from "./pages/founders/details";
import Founders from "./pages/founders/listing";

import AddEditPartner from "./pages/partners/add";
import PartnerDetails from "./pages/partners/details";
import Partners from "./pages/partners/listing";

import AddEditNRIStartup from "./pages/NRIStartups/add";
import NRIStartupDetails from "./pages/NRIStartups/details";
import NRIStartups from "./pages/NRIStartups/listing";

import AddEditPropertyEvent from "./pages/propertyEvents/add_edit";
import ClonePropertyEvent from "./pages/propertyEvents/clone";
import PropertyEventDetails from "./pages/propertyEvents/details";
import PropertyEvents from "./pages/propertyEvents/getAll";

import AddEditProperty from "./pages/property/add_edit";
import CloneProperty from "./pages/property/clone";
import PropertyDetails from "./pages/property/details";
import Properties from "./pages/property/getAll";

import AddEvent from "./pages/events/add";
import CloneEvent from "./pages/events/clone";
import EventDetails from "./pages/events/details";
import EditEvent from "./pages/events/edit";
import Events from "./pages/events/getAll";

import AddEditCohort from "./pages/cohorts/add_edit";
import CohortDetails from "./pages/cohorts/details";
import Cohorts from "./pages/cohorts/getAll";

import AddEditPage from "./pages/admin/pages/add_edit";
import PageDetails from "./pages/admin/pages/details";
import Pages from "./pages/admin/pages/getAll";

import AddEditDynamicHomepage from "./pages/admin/dynamicHomepages/add_edit";
import DynamicHomepageDetails from "./pages/admin/dynamicHomepages/details";
import DynamicHomepages from "./pages/admin/dynamicHomepages/getAll";

import APIIntegrations from "./pages/admin/APIIntegrations/APIIntegrations";


import Extractor from "./pages/extractor/extractor";

import RegisterNewsletter from "./pages/newsletter/register";

import GroupAppointment from "./pages/appointment/GroupAppointment";

import AddEditItems from "./pages/genericItems/add";
import ItemsDetails from "./pages/genericItems/details";
import Items from "./pages/genericItems/listing";

// automate campaign
import AutomateCampaign from "./pages/automateCampaign/automateCampaign";

import RedirectToExternal from "./pages/RedirectToExternal/RedirectToExternal";
// ! Unused Routes
import AddInstitute from "./components/institutes/add";
import CloneInstitute from "./components/institutes/clone";
import InstituteDetails from "./components/institutes/details";
import EditInstitute from "./components/institutes/edit";
import InstituteListing from "./components/institutes/getAll";
import Privacy from "./components/privacy/privacy";
import Terms from "./components/terms/terms";

export default function App() {
  const navigate = useNavigate();

  const currentPathname = window.location.pathname;

  const tokenDetails = TokenDetails();
  const userData = useUserData();
  const role = userData.role;
  const myUserId = tokenDetails?.Email;

  const siteName = SubDomainRouter("title");
  const keywords = SubDomainRouter("keywords");
  const favicon = SubDomainRouter("favicon");
  const homePageRoute = SubDomainRouter("homePageRoute");
  const homeNavItem = SubDomainRouter("homeNavItem");

  const configStatus = useSelector((state) => state.config.status);
  const configData = useSelector((state) => state.config.value);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    // Early exit if the configData is not available, status is 'failed', or userData is empty
    if (!configData || configStatus === 'failed' || Object.keys(userData).length === 0) {
      return;
    }

    // Set document title based on siteName or window.location.host
    document.title = siteName || window.location.host;

    // Check if we are on the home page
    if (currentPathname === "/") {
      // If homePageRoute matches the current path, no need to navigate
      if (homePageRoute === "/home") {
        setConfigLoading(false);
        return;
      }

      // Check if homeNavItem is valid and navigate if necessary
      if (!homeNavItem || homeNavItem === "hide" || homePageRoute !== currentPathname) {
        navigate(homePageRoute);
        return;
      }
    }

    // Set config loading to false if all conditions are met
    setConfigLoading(false);

  }, [
    configData,
    configStatus,
    currentPathname,
    homeNavItem,
    homePageRoute,
    navigate,
    siteName,
    userData,
  ]);


  useEffect(() => {
    if (favicon) {
      const updateFavicon = (favicon) => {
        const link =
          document.querySelector("link[rel~='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = favicon;
        document.getElementsByTagName("head")[0].appendChild(link);
      };

      updateFavicon(favicon);
    }
  }, [favicon, siteName]);


  const specRoles =
    role === "admin" ||
    role === "siteadmin" ||
    role === "superadmin" ||
    role === "trainer" ||
    role === "training-coordinator" ||
    myUserId === "ravi.chinna225@gmail.com";


  const adminRoles = role === "admin" ||
    role === "siteadmin" ||
    role === "superadmin"



  return (
    <>
      <Helmet>
        <title>{siteName ? siteName : window.location.host}</title>
        <meta name="keywords" content={keywords || ""} />
      </Helmet>
      {configLoading && configStatus === 'loading' ? (
        <CustomLoader height={"100vh"} className={"pt-0 mt-0 pb-0 mb-0"} />
      ) : (
        <>
          <Routes>
            {/* =================== Main pages =================================== */}
            {/* =====-with  layout======== */}
            <Route element={<RootLayout />}>
              <Route
                path={homePageRoute === "/" ? "/" : "/home"}
                exact
                element={<Home />}
              />
              <Route path={"/demos"} element={<Demos />} />
              <Route path={"/courses"} element={<CourseListing />} />
              <Route path={"/code-ai"} element={<ChatAI />} />
              <Route path={"/blogs"} element={<Blogs />} />
              <Route path={"/startup/items"} element={<Startups />} />

              <Route path="/pages/details/:id" element={<PageDetails />} />
              <Route
                path="/homepages/details/:id"
                element={<DynamicHomepageDetails />}
              />

              {/* =================== Main pages =================================== */}

              <Route path="/courses/details/:id" element={<CourseDetails />} />
              <Route path="/institutes" element={<InstituteListing />} />
              <Route path="/trainers" element={<Trainers />} />
              <Route
                path="/trainers/details/:id"
                element={<TrainerDetails />}
              />
              <Route path="/corporates" element={<Corporates />} />
              <Route
                path="/institutes/details/:id"
                element={<InstituteDetails />}
              />
              {/* reviews */}
              <Route path="/reviews" element={<Reviews />} />
              <Route
                path="/reviews/details/:entityType/:entityId/:id"
                element={<ReviewDetails />}
              />
              <Route path="/reviews/edit/:id" element={<ReviewEdit />} />
              <Route
                path="/reviews/:entityType/:id/add"
                element={<AddReview />}
              />
              {/* blogs */}
              <Route path="/blogs/details/:id" element={<BlogDetails />} />
              <Route path="/blogs/add" element={<AddBlog />} />
              <Route path="/blogs/edit/:id" element={<AddBlog />} />
              {/* demos */}
              <Route path="/demos/details/:id" element={<DemoDetails />} />
              <Route path="/demos/past" element={<PastDemos />} />
              <Route path="/demos/add" element={<DemoAdd />} />
              <Route path="/demos/clone/:id" element={<CloneDemo />} />
              <Route path="/demos/edit/:id" element={<DemoEdit />} />


              {/* Event */}
              <Route path={"/events"} element={<Events />} />
              <Route path="/events/add" element={<AddEvent />} />
              <Route path="/events/edit/:id" element={<EditEvent />} />
              <Route path="/events/clone/:id" element={<CloneEvent />} />
              <Route path="/events/details/:id" element={<EventDetails />} />

              {/*** ======================== Items ======================== ***/}
              {/* meetups */}
              <Route path="/meetups" element={<Meetups />} />
              {/* startups */}
              <Route path="/startup/items/add" element={<AddEditStartup />} />
              <Route path="/startup/items/edit/:id" element={<AddEditStartup />} />
              <Route
                path="/startup/items/details/:id"
                element={<StartupDetails />}
              />
              {/* speakers */}
              <Route path="/speaker/items" element={<Speakers />} />
              <Route path="/speaker/items/add" element={<AddEditSpeakers />} />
              <Route path="/speaker/items/edit/:id" element={<AddEditSpeakers />} />
              <Route
                path="/speaker/items/details/:id"
                element={<SpeakerDetails />}
              />
              {/* service-providers */}
              <Route path="/service-provider/items" element={<ServiceProvider />} />
              <Route
                path="/service-provider/items/add"
                element={<AddEditServiceProvider />}
              />
              <Route
                path="/service-provider/items/edit/:id"
                element={<AddEditServiceProvider />}
              />
              <Route
                path="/service-provider/items/details/:id"
                element={<ServiceProviderDetails />}
              />
              {/* mentors */}
              <Route path={"/mentor/items"} element={<Mentors />} />
              <Route path="/mentor/items/add" element={<AddEditMentors />} />
              <Route path="/mentor/items/edit/:id" element={<AddEditMentors />} />
              <Route path="/mentor/items/details/:id" element={<MentorDetails />} />

              {/* freelancers */}
              <Route path={"/freelancer/items"} element={<Freelancers />} />
              <Route path="/freelancer/items/add" element={<AddEditFreelancers />} />
              <Route
                path="/freelancer/items/edit/:id"
                element={<AddEditFreelancers />}
              />
              <Route
                path="/freelancer/items/details/:id"
                element={<FreelancerDetails />}
              />

              {/* interns */}
              <Route path={"/intern/items"} element={<Interns />} />
              <Route path="/intern/items/add" element={<AddEditIntern />} />
              <Route path="/intern/items/edit/:id" element={<AddEditIntern />} />
              <Route path="/intern/items/details/:id" element={<InternDetails />} />
              {/* founders */}
              <Route path={"/founder/items"} element={<Founders />} />
              <Route path="/founder/items/add" element={<AddEditFounder />} />
              <Route path="/founder/items/edit/:id" element={<AddEditFounder />} />
              <Route
                path="/founder/items/details/:id"
                element={<FounderDetails />}
              />
              {/* partners */}
              <Route path={"/partner/items"} element={<Partners />} />
              <Route path="/partner/items/add" element={<AddEditPartner />} />
              <Route path="/partner/items/edit/:id" element={<AddEditPartner />} />
              <Route
                path="/partner/items/details/:id"
                element={<PartnerDetails />}
              />
              {/* NRI-startups */}
              <Route path={"/NRI-startup/items"} element={<NRIStartups />} />
              <Route path="/NRI-startup/items/add" element={<AddEditNRIStartup />} />
              <Route
                path="/NRI-startup/items/edit/:id"
                element={<AddEditNRIStartup />}
              />
              <Route
                path="/NRI-startup/items/details/:id"
                element={<NRIStartupDetails />}
              />

              {/* PropertyEvent */}
              <Route path={"/propertyevent/items"} element={<PropertyEvents />} />
              <Route
                path="/propertyevent/items/add"
                element={<AddEditPropertyEvent />}
              />
              <Route
                path="/propertyevent/items/edit/:id"
                element={<AddEditPropertyEvent />}
              />
              <Route
                path="/propertyevent/items/clone/:id"
                element={<ClonePropertyEvent />}
              />
              <Route
                path="/propertyevent/items/details/:id"
                element={<PropertyEventDetails />}
              />
              {/* Property */}
              <Route path={"/property/items"} element={<Properties />} />
              <Route path="/property/items/add" element={<AddEditProperty />} />
              <Route path="/property/items/edit/:id" element={<AddEditProperty />} />
              <Route path="/property/items/clone/:id" element={<CloneProperty />} />
              <Route
                path="/property/items/details/:id"
                element={<PropertyDetails />}
              />


              {/* Cohort */}
              <Route path="/cohort/items" element={<Cohorts />} />
              <Route path="/cohort/items/add" element={<AddEditCohort />} />
              <Route path="/cohort/items/edit/:id" element={<AddEditCohort />} />
              <Route path="/cohort/items/details/:id" element={<CohortDetails />} />

              {/* items genic route */}
              <Route
                path="/:item_type/items/add"
                element={<AddEditItems />}
              />
              <Route
                path="/:item_type/items/edit/:id"
                element={<AddEditItems />}
              />
              <Route
                path="/:item_type/items/details/:id"
                element={<ItemsDetails />}
              />
              <Route
                path="/:item_type/items"
                element={<Items />}
              />

              {/*** ======================== Items ======================== ***/}

              {/* ======== General pages ========= */}
              <Route path="/appointment" element={<GroupAppointment />} />
              <Route path="/newsletter" element={<RegisterNewsletter />} />
              <Route path="/subscribe" element={<RegisterNewsletter />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/pay" element={<Pay />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/loginsuccess" element={<LoginSuccess />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/share/:entityType/:id" element={<ShareData />} />
              <Route path="/register/:entityType/:id" element={<Register />} />
              <Route
                path="/enquiries/:entityType/:id/add"
                element={<Enquiry />}
              />
              <Route path="/coordinator/join" element={<Coordinator />} />
              <Route path="/trainer/join" element={<JoinAsTrainer />} />
              <Route path="/my-profile" element={<Profile />} />
              <Route path="/profile/edit" element={<ProfileEdit />} />
              <Route path="/how-it-works" element={<HowItWorks />} />

              <Route
                path="/request-sponsor/:entityType/:id"
                element={<RequestSponsorship />}
              />

              {/* group */}
              <Route path="/groups/add" element={<GroupAdd />} />
              <Route path="/groups/edit/:id" element={<GroupEdit />} />
              <Route path="/groups/items/:id" element={<GroupItems />} />
              <Route path="/my-group/:id" element={<UniqueItems />} />

              {/* superadmin Routes */}
              {role === "superadmin" && (
                <Route
                  path="/super-admin-dashboard/*"
                  element={<SuperAdminDashboard />}
                >
                  <Route
                    path="config-management"
                    element={<ConfigManagement />}
                  />
                  <Route path="config/add" element={<AddConfigs />} />
                  <Route path="config/edit/:id" element={<EditConfig />} />
                  <Route path="config/clone/:id" element={<CloneConfig />} />

                  <Route path="users" element={<SuperAdminUsers />} />
                  <Route path="users/add" element={<SuperAdminUsersAdd />} />
                  <Route
                    path="users/edit/:id"
                    element={<SuperAdminUsersEdit />}
                  />

                  <Route path="extractor" element={<Extractor />} />
                </Route>
              )}
              {/* ________/\_________ */}
              {/* coordinatorDashboard routes for admins */}
              {role === "admin" ||
                role === "siteadmin" ||
                role === "superadmin" ? (
                // {/* coordinator Dashboard Routes */}
                <Route
                  path="/coordinator-dashboard/*"
                  element={<CoordinatorDashboard />}
                >
                  <Route path="my-trainers" element={<MyTrainers />} />
                  <Route index path="my-demos" element={<MyDemos />} />
                  <Route index path="my-events" element={<MyEvents />} />
                  <Route path="my-courses" element={<MyCourses />} />
                </Route>
              ) : null}

              {role === "training-coordinator" ? (
                // {/* coordinator Dashboard Routes */}
                <Route
                  path="/coordinator-dashboard/*"
                  element={<CoordinatorDashboard />}
                >
                  <Route path="my-trainers" element={<MyTrainers />} />
                  <Route index path="my-demos" element={<MyDemos />} />
                  <Route index path="my-events" element={<MyEvents />} />
                  <Route path="my-courses" element={<MyCourses />} />
                </Route>
              ) : (
                // {/* Dashboard Routes */}
                <Route path="/dashboard/*" element={<MyDashboard />}>
                  <Route path="my-groups" element={<MyGroups />} />
                  <Route index path="my-demos" element={<MyDemos />} />
                  <Route index path="my-events" element={<MyEvents />} />
                  <Route path="my-courses" element={<MyCourses />} />
                </Route>
              )}
              {/* ________/ Dashboard Pages \_________ */}
              {role === "siteadmin" || role === "superadmin" ? (
                <>
                  {/* Page */}
                  <Route path="/pages/add" element={<AddEditPage />} />
                  <Route path="/pages/edit/:id" element={<AddEditPage />} />
                  <Route
                    path="/pages/:actionType/:id"
                    element={<AddEditPage />}
                  />

                  {/* DynamicHomepage */}
                  <Route
                    path="/homepages/add"
                    element={<AddEditDynamicHomepage />}
                  />
                  <Route
                    path="/homepages/edit/:id"
                    element={<AddEditDynamicHomepage />}
                  />
                  <Route
                    path="/homepages/:actionType/:id"
                    element={<AddEditDynamicHomepage />}
                  />

                </>
              ) : null}

              {role === "siteadmin" || role === "superadmin" || role === "admin" || role === "uidev" ?
                <Route path="/admin" element={<AdminPage />}>
                  <Route path={"homepages"} element={<DynamicHomepages />} />
                  <Route path={"pages"} element={<Pages />} />
                  <Route path="upload" element={<UploadFiles />} />
                  <Route
                    path="content-generator"
                    element={<ContentGenerator />}
                  />

                </Route> :
                null}

              {adminRoles ? (
                // !Routes for admin and superadmin
                <>
                  <Route
                    path="/campaigns-dashboard/*"
                    element={<CampaignDashboard />}
                  >
                    <Route path="newsletter" element={<DailyNewsletter />} />
                    <Route path="analytics" element={<Analytics />} />
                  </Route>

                  {/* newsletter email */}
                  <Route
                    path="/newsletter/:entityType/:dayCount"
                    element={<NewsletterCampaign />}
                  />

                  <Route path="/admin" element={<AdminPage />}>
                    <Route path="users" element={<Users />} />
                    <Route
                      path="registrants"
                      element={<AllEventsRegistrants />}
                    />
                    <Route path="configs" element={<ConfigsPage />} />

                    <Route
                      path="api-integrations"
                      element={<APIIntegrations />}
                    />
                  </Route>

                  <Route path="/admin/users/edit/:id" element={<UsersEdit />} />
                  <Route path="/trainers/add" element={<TrainerAdd />} />
                </>
              ) : null}

              {/* Routes accessible to admin */}
              {specRoles && (
                <>
                  <Route path="/courses/add" element={<AddCourse />} />
                  <Route path="/courses/edit/:id" element={<EditCourse />} />
                  <Route path="/courses/clone/:id" element={<CloneCourse />} />

                  <Route path="/institutes/add" element={<AddInstitute />} />
                  <Route
                    path="/institutes/edit/:id"
                    element={<EditInstitute />}
                  />
                  <Route
                    path="/institutes/clone/:id"
                    element={<CloneInstitute />}
                  />

                  <Route path="/trainers/edit/:id" element={<TrainerEdit />} />

                  <Route
                    path="/registrants/:entity/:id"
                    element={<Registrants />}
                  />

                  {/* contacts */}
                  <Route path="/my-contacts/*" element={<MyContacts />}>
                    <Route path="all-contacts" element={<AllContacts />} />
                    <Route path="email-lists" element={<EmailLists />} />
                    <Route path="whatsapp-lists" element={<WhatsappLists />} />
                    <Route path="telegram-lists" element={<TelegramLists />} />
                    <Route path="sms-lists" element={<PhoneLists />} />
                  </Route>
                  {/* EmailLists */}
                  <Route
                    path="/my-contacts/email-list/contact/:id"
                    element={<EmailContacts />}
                  />
                  <Route
                    path="/my-contacts/email-list/contact/:listId/:contactId/edit"
                    element={<EmailListContactForm />}
                  />
                  <Route
                    path="/my-contacts/email-list/contact/:listId/add"
                    element={<EmailListContactForm />}
                  />
                  <Route
                    path="/my-contacts/email-list/add"
                    element={<EmailListAdd />}
                  />
                  <Route
                    path="/my-contacts/email-list/:id/edit"
                    element={<EmailListAdd />}
                  />
                  {/* WhatsappLists */}
                  <Route
                    path="/my-contacts/whatsapp-list/:id/edit"
                    element={<WhatsappListsAddForm />}
                  />
                  <Route
                    path="/my-contacts/whatsapp-list/add"
                    element={<WhatsappListsAddForm />}
                  />
                  <Route
                    path="/my-contacts/whatsapp-list/contact/:listId/:contactId/edit"
                    element={<WhatsappListContactForm />}
                  />
                  <Route
                    path="/my-contacts/whatsapp-list/contact/:listId/add"
                    element={<WhatsappListContactForm />}
                  />
                  <Route
                    path="/my-contacts/whatsapp-list/contact/:id"
                    element={<WhatsappContacts />}
                  />
                  {/* TelegramLists */}
                  <Route
                    path="/my-contacts/telegram-list/:id/edit"
                    element={<TelegramListsAddForm />}
                  />
                  <Route
                    path="/my-contacts/telegram-list/add"
                    element={<TelegramListsAddForm />}
                  />
                  <Route
                    path="/my-contacts/telegram-list/contact/:listId/:contactId/edit"
                    element={<TelegramListContactForm />}
                  />
                  <Route
                    path="/my-contacts/telegram-list/contact/:listId/add"
                    element={<TelegramListContactForm />}
                  />
                  <Route
                    path="/my-contacts/telegram-list/contact/:id"
                    element={<TelegramContacts />}
                  />
                  {/* PhoneLists */}
                  <Route
                    path="/my-contacts/sms-list/:id/edit"
                    element={<PhoneListsAddForm />}
                  />
                  <Route
                    path="/my-contacts/sms-list/add"
                    element={<PhoneListsAddForm />}
                  />
                  <Route
                    path="/my-contacts/sms-list/contact/:listId/:contactId/edit"
                    element={<PhoneListContactForm />}
                  />
                  <Route
                    path="/my-contacts/sms-list/contact/:listId/add"
                    element={<PhoneListContactForm />}
                  />
                  <Route
                    path="/my-contacts/sms-list/contact/:id"
                    element={<PhoneContacts />}
                  />

                  {/* campaigns */}
                  <Route
                    path="/campaigns-dashboard/*"
                    element={<CampaignDashboard />}
                  >
                    <Route
                      index
                      path="email-campaign"
                      element={<EmailCampaign />}
                    />
                    <Route path="templates" element={<Templates />} />
                    <Route path="drafts" element={<CampaignDrafts />} />
                    <Route
                      path="whatsapp-campaign"
                      element={<WhatsAppCampaign />}
                    />
                    <Route
                      path="telegram-campaign"
                      element={<TelegramCampaign />}
                    />
                    <Route path="sms-campaign" element={<SMSCampaign />} />
                  </Route>


                  <Route
                    path="/campaigns-dashboard/email-campaign/draft/:id"
                    element={<DraftForm />}
                  />
                  <Route
                    path="/campaigns-dashboard/template/edit/:id"
                    element={<TemplateForm />}
                  />
                  <Route
                    path="/campaigns-dashboard/template/add"
                    element={<TemplateForm />}
                  />

                  {/* Email template --generated */}
                  <Route path="/campaigns/email" element={<GeneratedEmail />} />
                </>
              )}

              <Route
                path="/campaigns/:entityType"
                element={<AutomateCampaign />}
              />

              <Route
                path="/accelerator/register"
                element={<AcceleratorRegister />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            {/* =====-with out layout======== */}
            <Route path="/guide/instructor" element={<InstructorGuide />} />
            <Route path="/guide/coordinator" element={<CoordinatorGuide />} />


            {configData?.appsettingsarrayobjects?.dynamicUrls?.map((url, index) => (
              <Route
                key={url.key}
                path={`/${url.key}`}
                element={<RedirectToExternal to={url.value} />}
              />
            ))}

          </Routes>


        </>
      )}

      <ToastContainer limit={2} position="bottom-right" />
    </>
  );
}
